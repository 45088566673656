<template>
  <div class="px-10" data-testid="site-data-extract-page">
    <Breadcrumbs />

    <v-row>
      <v-col sm="6" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ userTrialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="trial-title">Trial data extract</h4>
      </v-col>
    </v-row>

    <BannerButton @click="handleBlankFormClick">
      <template #image>
        <ImgBlankForm />
      </template>
      <h4 class="mb-4">Blank form</h4>
      <div>Download a blank form</div>
    </BannerButton>

    <BannerButton @click="handleArchiveClick">
      <template #image>
        <ImgDataArchive />
      </template>
      <h4 class="mb-4">Data archive</h4>
      <div>Download final archive</div>
    </BannerButton>

    <BannerButton @click="handleReportsClick">
      <template #image>
        <ImgReport />
      </template>
      <h4 class="mb-4">Reports</h4>
      <div>Request and download reports</div>
    </BannerButton>
  </div>
</template>

<script>
import ImgDataArchive from '@/components/images/ImgDataArchive'
import BannerButton from '@/components/BannerButton'
import ImgReport from '@/components/images/ImgReport'
import Breadcrumbs from '@/components/layout/Breadcrumbs'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'
import ImgBlankForm from '@/components/images/ImgBlankForm'

export default {
  name: 'Reports',
  mixins: [userGettersMixin],
  components: {
    ImgBlankForm,
    Breadcrumbs,
    ImgReport,
    BannerButton,
    ImgDataArchive,
  },
  methods: {
    handleBlankFormClick() {
      this.$router.push({
        name: 'trialBlankForm',
      })
    },
    handleReportsClick() {
      this.$router.push({
        name: 'trialReports',
      })
    },
    handleArchiveClick() {
      this.$router.push({
        name: 'trialSponsorArchive',
      })
    },
  },
}
</script>

<style scoped></style>
